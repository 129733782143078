import React, { useState } from 'react'
import "./index.css"
import SyntaxHighlighter from 'react-syntax-highlighter';
import { anOldHope } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import md5 from 'md5'
import BrowserFrame from "react-browser-frame";

function SelectEvent({ event, toggle }) {

	if (event.checked) return <span className="select_event checked" onClick={() => toggle(event.title)}>{event.title}</span>

	return <span className="select_event" onClick={() => toggle(event.title)}>{event.title}</span>
}

function PickEvent({ event, selectedEvent, select }) {

	if (event.title === selectedEvent) return <span className="select_event checked">{event.title}</span>

	return <span className="select_event" onClick={() => select(event.title)}>{event.title}</span>
}

function PickUserGroup({ name, userGroup, setUserGroup }) {

	if (name === userGroup) return <span className="select_event checked">{name}</span>

	return <span className="select_event" onClick={() => setUserGroup(name)}>{name}</span>
}

const rawEvents = [
	{
		title: "↑ Create lead", checked: true, products: [
			{ l: "https://logo.clearbit.com/salesforce.com?size=80", n: "Salesforce" },
			{ l: "https://logo.clearbit.com/hubspot.com?size=80", n: "HubSpot" },
			{ l: "https://logo.clearbit.com/pipedrive.com?size=80", n: "Pipedrive" },
			{ l: "https://logo.clearbit.com/zoho.com?size=800", n: "Zoho" },
			{ l: "https://logo.clearbit.com/copper.com?size=80", n: "Copper" },
		],
		value: "CREATE_LEAD",
		post: true,
		input: {
			"firstName": "Bob",
			"lastName": "Dylan",
			"email": "bob@dylan.com",
			"companyName": "Dylan records",
			"owner": "Bob",
			"title": "CEO",
			"departement": "direction",
			"phone": "+33627715935",
			"fax": "+33627715935",
			"mailingAdress": "bob@dylan.com",
			"address": "12 street bob, 67839 BobTown, Mars"
		}
	},
	{
		title: "↑ Update lead", checked: true, products: [
			{ l: "https://logo.clearbit.com/salesforce.com?size=80", n: "Salesforce" },
			{ l: "https://logo.clearbit.com/hubspot.com?size=80", n: "HubSpot" },
			{ l: "https://logo.clearbit.com/pipedrive.com?size=80", n: "Pipedrive" },
			{ l: "https://logo.clearbit.com/zoho.com?size=800", n: "Zoho" },
			{ l: "https://logo.clearbit.com/copper.com?size=80", n: "Copper" },
		],
		value: "UPDATE_LEAD",
		post: true,
		input: {
			"id": "1234",
			"firstName": "Bob",
			"lastName": "Dylan",
			"email": "bob@dylan.com",
			"companyName": "Dylan records",
			"owner": "Bob",
			"title": "CEO",
			"departement": "direction",
			"phone": "+33627715935",
			"fax": "+33627715935",
			"mailingAdress": "bob@dylan.com",
			"address": "12 street bob, 67839 BobTown, Mars"
		}
	},
	{
		title: "↑ Create invoice", checked: false, products: [
			{ n: "QuickBooks", l: "https://zupimages.net/up/20/52/38h1.png" },
			{ n: "Chargebee", l: "https://logo.clearbit.com/chargebee.com?size=80" },
			{ n: "Zoho", l: "https://logo.clearbit.com/zoho.com?size=80" },
			{ n: "Stripe", l: "https://logo.clearbit.com/stripe.com?size=80" },
			{ n: "Freshbooks", l: "https://logo.clearbit.com/freshbooks.com?size=80" },
		],
		value: "CREATE_INVOICE",
		post: true,
		input: {
			"InvoiceNumber": "INV2020-09_01",
			"customer": {
				"firstName": "Joe",
				"lastName": "Customer",
				"customerId": "53627",
				"email": "joe@customer.com",
				"phone": "+33627715935",
				"companyName": "JoeCompany",
				"billing_address": {
					"address": "String",
					"street2": "String",
					"city": "String",
					"state": "String",
					"zipcode": "String",
					"country": "String",
					"phone": "String",
					"fax": "String"
				},
				"shipping_address": {
					"address": "String",
					"street2": "String",
					"city": "String",
					"state": "String",
					"zipcode": "String",
					"country": "String",
					"phone": "String",
					"fax": "String"
				}
			},
			"reference": "TransactionNumber",
			"currency": {
				"code": "USD",
				"name": "dollar"
			},
			"exchange_rate": 1.5,
			"date": "2020-01-10",
			"dueDate": "2020-01-30",
			"sub_total": 53,
			"total_tax": 10,
			"total_amount": 63,
			"amount_paid": 63,
			"status": "open",
			"sent": true,
			"description": "This is a description",
			"tax": {
				"code": "3526",
				"name": "TVA",
				"rate": 19.6
			}
		}

	},
	{
		title: "↑ Send notification", checked: false, products: [
			{ n: "Slack", l: "https://logo.clearbit.com/slack.com?size=80" },
			{ n: "Microsoft Teams", l: "https://zupimages.net/up/20/52/ovwh.png" },
			{ n: "Gmail", l: "https://zupimages.net/up/20/52/4x7e.jpg" },
		],
		value: "SEND_NOTIF",
		post: true,
		input: {
			"id": "1234",
			"conversationId": "12345",
			"sender": "123456",
			"subject": "Hello",
			"text": "This is a message",
			"parent": "Unique identifier of the message to be replied"
		}
	},
	{
		title: "↓ New company created", checked: true, products: [
			{ l: "https://logo.clearbit.com/salesforce.com?size=80", n: "Salesforce" },
			{ l: "https://logo.clearbit.com/hubspot.com?size=80", n: "HubSpot" },
			{ l: "https://logo.clearbit.com/pipedrive.com?size=80", n: "Pipedrive" },
			{ l: "https://logo.clearbit.com/zoho.com?size=800", n: "Zoho" },
			{ l: "https://logo.clearbit.com/copper.com?size=80", n: "Copper" },
		],
		value: "NEW_COMPANY_CREATED",
		post: false,
		input: {
			"id": "5363",
			"name": "Simplynk, Inc.",
			"owner": "uMDA1N0YwMDAwMDQxQXpnUUFF",
			"industry_code": "541519",
			"industry": "Information Technology",
			"employees": 4,
			"rating": "Hot",
			"fax": "+33627715935",
			"phone": "+33627715935",
			"annual_revenue": "100000",
			"billing_address": "12 street Bob, 67382 Bob Mars",
			"shipping_address": "12 street Bob, 67382 Bob Mars",
			"website": "https://angel.co/simplynk",
			"description": "Location-based professional networking"

		}
	},
	{
		title: "↑ Create task", checked: false, products: [
			{ n: "Trello", l: "https://logo.clearbit.com/trello.com?size=80" },
			{ n: "Asana", l: "https://logo.clearbit.com/asana.com?size=80" },
			{ n: "Monday", l: "https://zupimages.net/up/20/52/rwxm.png" },
			{ n: "Planner", l: "https://zupimages.net/up/20/52/gaj8.png" },
		],
		value: "CREATE_TASK",
		post: true,
		input: {
			"id": "G3627",
			"name": "Create the task store",
			"Description": "Create a awesome store using Simplifier",
			"duedate": "2020-12-02",
			"status": "Completed",
			"position": "bottom",
			"followers": "me",
			"notes": "This is notes",
			"startDate": "2020-11-30",
			"relatedId": "52637",
			"priority": "High",
			"createdDate": "2020-11-29",
			"type": "task",
			"assignee": "joe@joe.com"
		}
	},
	{
		title: "↓ File uploaded", checked: false, products: [
			{ n: "Dropbox", l: "https://logo.clearbit.com/dropbox.com?size=80" },
			{ n: "Box", l: "https://logo.clearbit.com/box.com?size=80" },
			{ n: "Google drive", l: "https://zupimages.net/up/20/52/8kby.png" },
			{ n: "Onedrive", l: "https://zupimages.net/up/20/52/qo7q.png" },
		],
		value: "GET_DOWNLOAD_URL",
		post: true,
	},
	{
		title: "↑ List calendars", checked: false, products: [
			{ n: "Google calendar", l: "https://zupimages.net/up/20/52/qwz9.png" },
			{ n: "Outlook calendar", l: "https://zupimages.net/up/20/52/2b64.png" },
		],
		value: "LIST_CALENDARS",
		post: true,
		input: {}
	},
	{
		title: "↑ Create channel", checked: false, products: [
			{ n: "Slack", l: "https://logo.clearbit.com/slack.com?size=80" },
			{ n: "Microsoft Teams", l: "https://zupimages.net/up/20/52/ovwh.png" },
		],
		value: "CREATE_CHANNEL",
		post: true,
		input: {
			"name": "My new channel",
			"private": true,
		}
	},
	{
		title: "↓ E-commerce order created", checked: false, products: [
			{ n: "Shopify", l: "https://zupimages.net/up/20/52/tqqe.png" },
			{ n: "Woo commerce", l: "https://logo.clearbit.com/woocommerce.com?size=80" },
			{ n: "Magento", l: "https://logo.clearbit.com/magento.com?size=80" },
		],
		value: "NEW_ORDER_CREATED",
		post: false,
	},
]

const hljs = {
	...anOldHope.hljs,
	backgroundColor: "#151619",
	padding: 20,
	borderRadius: 10,
	fontFamily: "Fira Code",
	fontSize: 18,
	maxWidth: 700
}

function App() {

	const [events, setEvents] = useState(rawEvents)

	const [selectedEvent, selectEvent] = useState("")

	const [userGroup, setUserGroup] = useState("Specific user")

	const toggleEvent = title => {
		let evs = events.map(ev => {
			if (ev.title === title) {
				ev.checked = !ev.checked
				return ev
			}
			return ev
		})
		setEvents(evs)
	}

	let selectedEvents = events.filter(ev => ev.checked)

	let selectedProducts = []

	for (let event of selectedEvents) {
		for (let product of event.products) {
			if (!selectedProducts.map(p => p.n).includes(product.n)) {
				selectedProducts.push(product)
			}
		}
	}

	let selectedEventData = null

	for (let event of events) {
		if (event.title === selectedEvent) selectedEventData = event
	}

	return (
		<div className="App" >
			<div className="grain"></div>
			<h3><span style={{ color: "#35342f" }}>integrations</span><span style={{ color: "#f45844" }}>.love</span></h3>
			<h1>Building a product isn't cool.<br />You know what's cool?<br />Building an < a href="#start" > ecosystem</a>.</h1>

			<br />
			<br />
			<br />
			<br />

			<h2 id="start" style={{ lineHeight: 1.2 }}>Why? <span className="secondary">Companies like Asana, Slack, or Shopify succeeded early by spending time and money to create lots of integrations with their product. It was complex, expensive, and time consuming. To make that easier for you, we built an "Integrations Store as a Service".</span></h2>


			<br />
			<br />
			<br />
			<br />

			<h2 id="start" style={{ lineHeight: 1.2 }}>Integrations Store as a Service: <span className="secondary">Increase user adoption, engagement, and retention of your software by connecting it to dozens of 3rd party integrations at once. We handle all the complexity and you pay as you go. Get started in minutes.</span></h2>

			<br />

			<h1>What you do:</h1>

			<h2><span className="circled">1</span>&nbsp;Set up events & products</h2>

			<p>

				{events.map(ev => <SelectEvent event={ev} toggle={toggleEvent} />)}<br />

				<span className="explicative" style={{ fontStyle: "italic" }}>+45 other event templates & +100 products available, or add your own ones</span>

				<br />
				<br />

				<span className="explicative">Integrated products :</span> {selectedProducts.map(product => <img src={product.l} title={product.n} style={{ width: 40, borderRadius: 5, opacity: 1, verticalAlign: "middle", marginRight: 10, backgroundColor: "white", position: "relative", top: -4 }} />)}
			</p>

			<h2><span className="circled">2</span>&nbsp;Get the authentication links</h2>


			<div class="flexbox-container">
				<div>
					<SyntaxHighlighter language="html" style={{ ...anOldHope, hljs }}>
						{`${selectedProducts.map(product => `<img src="https://integrations.love/logos/${md5(product.n).slice(17)}.png"/>\n<a href="https://integrations.love/auth/${md5(product.n).slice(17)}">\n    Connect ${product.n}\n</a>`).join('\n\n')}`}
					</SyntaxHighlighter>
				</div>
				<div style={{ marginTop: 18 }}>

					<BrowserFrame url="https://integrations.dundermifflin.com">
						<div style={{ backgroundColor: "#f1f1f1", padding: 20, cursor: 'default' }}>
							{selectedProducts.map(product => <div style={{ marginTop: 5, marginBottom: 20, minHeight: 60, borderRadius: 10, boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
								<img src={product.l} title={product.n} style={{ display: "inline-block", width: 50, borderRadius: 5, opacity: 1, verticalAlign: "middle", margin: 10, backgroundColor: "white", }} />
								<div style={{ margin: 0, marginLeft: 10, display: "inline-block" }}>
									<h4 style={{ margin: 0, position: "relative", top: 2, fontSize: 18 }}>Connect {product.n}</h4>
									{/* <p style={{ margin: 0 }}></p> */}
								</div>
							</div>)}
						</div>
					</BrowserFrame>

				</div>
			</div>




			<h2><span className="circled">3</span>&nbsp;Send & receive data</h2>

			<div class="flexbox-container">
				<div>
					<h3 className="trigger_title">Event</h3>
					{selectedEvents.map(ev => <PickEvent event={ev} select={selectEvent} selectedEvent={selectedEvent} />)}

					<br />

					<h3 className="trigger_title">User</h3>

					<PickUserGroup name="Specific user" userGroup={userGroup} setUserGroup={setUserGroup} />
					<PickUserGroup name="Group of users" userGroup={userGroup} setUserGroup={setUserGroup} />
					<PickUserGroup name="All users" userGroup={userGroup} setUserGroup={setUserGroup} />

				</div>
				<div style={{ marginTop: 18 }}>

					{selectedEventData ? <>

						{selectedEventData.post ? <>
							<SyntaxHighlighter wrapLongLines language="javascript" style={{ ...anOldHope, hljs }}>
								{`// ${selectedEventData.title} in products: \n// ${selectedEventData.products.map(p => `${p.n}`).join(' or ')}\n// depending on which one the user set up\n\nconst [integrations, results, errors] = await simplifier.emit(
    "${selectedEventData.value}", 
    "${userGroup === "Specific user" ? "michael@dundermifflin.com" : (userGroup === "Group of users" ? ".*@dundermifflin.com" : ".*")}", 
    ${(JSON.stringify(selectedEventData.input, null, 2) || "{}").split(/\r?\n/).join('\n    ')}
)`}
							</SyntaxHighlighter>

						</> : <>
						<SyntaxHighlighter wrapLongLines language="javascript" style={{ ...anOldHope, hljs }}>
								{`// Executes callback when ${selectedEventData.title} in products: \n// ${selectedEventData.products.map(p => `${p.n}`).join(' or ')}\n// depending on which one the user set up\n\nsimplifier.on('${selectedEventData.value}', (userAlias, data, integration) => {
    console.log(data)
})`}
							</SyntaxHighlighter>
							</>}



					</> : <><SyntaxHighlighter wrapLongLines language="javascript" style={{ ...anOldHope, hljs }}>
								{`// Select an event in the left panel`}
							</SyntaxHighlighter></>}



				</div>
			</div>

<br />
<hr />
<h1 style={{textAlign: 'center'}}>Get an <a href="https://uzk0xnlo8bm.typeform.com/to/ZWXLssI4" target="_blank">invite</a></h1>
<hr />


<h1>What we do:</h1>

			<h1 style={{ lineHeight: 1.2, textAlign: "left", fontSize: "7vh" }}>
				<span className="emphasis">Seamless user auth</span>—
				<span className="tertiary">OAuth1</span>—
				<span className="secondary">OAuth2</span>—
				<span className="tertiary">Custom auth schemes</span>—
				<span className="emphasis">Token refresh</span>—
				<span className="tertiary">API Key authentication</span>—
				<span className="emphasis">Data mapping</span>—
				<span className="secondary">Data typecast</span>—
				<span className="tertiary">Date format converter</span>—
				<span className="emphasis">Logs management</span>—
				<span className="secondary">Users management</span>—
				<span className="tertiary">Retry strategy</span>—
				<span className="emphasis">Errors triage</span>—
				<span className="tertiary">Error rate alerts</span>—
				<span className="emphasis">Logic editor</span>—
				<span className="secondary">Virtual webhooks</span>—
				<span className="tertiary">Recurrent trigger</span>—
				<span className="emphasis">Status monitoring</span>—
				<span className="secondary">Weird APIs handling</span>—
				<span className="emphasis">100+ connectors</span>—
				<span className="tertiary">New connector management</span>—
				<span className="emphasis">"Add your own API"</span>—
				<span className="secondary">Custom OAuth Apps</span>—
				<span className="emphasis">In app testing</span>—
				<span className="tertiary">Advanced versioning</span>—
				<span className="emphasis">Tokens encryption</span>—
				<span className="secondary">Documentation generator</span>—
				<span className="emphasis">Pay per use (no upfront)</span>—
				<span className="secondary">Our full support and help</span>
			</h1>
		</div >
	);
}

export default App;
